<template>
  <div>
    <div id="chart">
      <apexchart
        type="area"
        height="350"
        :options="chartOptions"
        :series="series"
      />
      <h2
        v-if="livetotal"
        class="primary"
      >
        Live Total: <span>{{ livetotal.toLocaleString() }}</span>
      </h2>
    </div>
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
import Vue from 'vue'

Vue.component('apexchart', VueApexCharts)
/*  eslint-disable */ 
export default {
  mounted(){
document.title = 'Stats - Brime Live'
  },
  async beforeCreate() {
    this.stats = [3700, 4978, 6156, 8110]
    this.livetotal = parseInt(await window.brime.getUsersTotal())
    this.stats.push(this.livetotal)
    this.updateChart()
  },
  data: function(){
      return {
        livetotal: null,
        options: 0,
        chartOptions: {
          theme: {
      mode: 'dark', 
      palette: 'palette1',
  },
          chart: {
            background: '#161d31',
              height: 350,
              zoom: {
                enabled: true
              }
            },
            dataLabels: {
              enabled: false
            },
          xaxis: {
            categories: ['JAN', 'MARCH', 'APRIL', 'MAY', 'JUNE']
          },
           fill: {
    type: "gradient",
    gradient: {
      gradientToColors: ["#8439AF", "#BB3778", "#FC3537"]
    }
  },
           title: {
    text: "Registered Users",
    align: "left",
    style: {
      fontSize: "25px"
    }
  },
        },
        series: [{
          name: "Registered Users",
          data: this.stats
        }]
      }
    },
     methods: {
  updateChart() {
   const max = 90;
   const min = 20;
   const newData = this.stats
   // In the same way, update the series option
   this.series = [{
    data: newData
   }]
  }
 }
};
</script>
<style scoped>
#chart{
width: 50%;
}
@media all and (max-width: 767px) {
#chart{
width: 100% !important;
}

}
</style>